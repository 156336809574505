import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'

const IndexPage = ({ data }) => (
	<Layout>
		<div className="intro">
			<aside>
				<Img fixed={data.file.childImageSharp.fixed} alt="pic of Avi Aryan" />
				<ul className="social-links">
					<li>
						<a href="https://twitter.com/aviaryan123">Twitter</a>
					</li>
					<li>
						<a href="https://github.com/aviaryan">GitHub</a>
					</li>
					<li>
						<a href="https://www.linkedin.com/in/aviaryan/">Linkedin</a>
					</li>
					{/* <li><a href="https://www.twitch.tv/aviaryan">Twitch</a></li> */}
					{/* <li><a href="https://www.instagram.com/aviaryan123/">Instagram</a></li> */}
				</ul>
			</aside>
			<div className="intro-text">
				<p>Hi,</p>
				<p>This is Avi, a full-stack web developer and consultant.</p>
				<p>My specialization is solving business problems efficiently using modern technologies.</p>
				<p>
					You can hire me through <a href="https://bit.ly/avitoptal">Toptal</a> or by directly{' '}
					<a href="mailto:avi@aviaryan.com">reaching out to me</a>.
				</p>
				<p>
					Check out my work on <a href="https://github.com/aviaryan">GitHub</a> and{' '}
					<a href="https://www.linkedin.com/in/aviaryan/">LinkedIn</a>.
				</p>
				<p>I have also given talks here and there and here is a complete list.</p>
				<ul className="talks">
					<li>Open Event API - FOSSASIA 2017</li>
					<li>Creating amazing APIs in Flask with Flask RESTplus - PyCon Thailand 2018</li>
					<li>Creating mobile ready web apps in 2018 - GDG Siberia Devfest 2018</li>
				</ul>
			</div>
		</div>
	</Layout>
)

export default IndexPage

export const query = graphql`
	query {
		file(relativePath: { eq: "pic_light.jpg" }) {
			childImageSharp {
				fixed(width: 200) {
					...GatsbyImageSharpFixed
				}
			}
		}
	}
`
